import React from 'react';
import Footer from '../Footer';
import webshs from './webshop.jpg'
import './stil.css';
import {Helmet} from 'react-helmet';

function Webshop(){
  return(
    <div>
      <Helmet>
        <title>Izrada web shop-a već od 400KM</title>
        <meta name="description" content="Vršimo izradu web trgovina (web shop) već od 400 KM. " />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Izrada web prodavnica</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Unaprijedite svoj biznis i pređite na online trgovanje putem vlastite web trogovine. Naš iskusni tim vam može izvršiti izradu moderne web trgovine sa svim funkcionalnostima.</p>
    <p>Vršimo izradu modernih web trgovina sa posebno prilagođenim dizajnom. Naš moto je da vam izradimo web trogovinu koja će povećati vašu prodaju te koja neće imati nikakve smetnje u radu. Osim toga cilj nam je da vašim posjetiocima (kupcima) omogućimo ugodan i jednostavan način online kupovine</p>
    <img src={webshs} className="slikasred"/>
    <p>Izradu web trgovina radimo na raznim CMS sistemima kao i izradu posebnih custom trgovina. Izbor načina izrade zavisi od vašeg budžeta te od kompleksnosti vašeg projekta.</p>

    <p>Ako imate već postojeću web trgovinu a želite da istu unaprijedite u bilo kojem smislu ali nemate dovoljno sredstava za novi projekat slobodno nam se javite a mi ćemo vam ponuditi unapređenje vaše web trgovine spram vašeg budžeta koji ste odredili za taj projekat. </p>

    <p>Imate web trgovinu ali nemate osobu koja vrši održavanje iste pa vam se dešavaju smetnje u radu!Ako je ovo vaš slučaj slobodno nam se javite.</p>

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.Izrada moderne web trgovine na WordPress platofrmi kreće se od 400 KM pa na više u zavisnosti od kompleksnosti projekta. U sklopu ove cijene dobijate potpuno funkcionalnu WordPress trgovinu, koja prima automatski narudžbe te vrši dostavu narudžbenice na vaš email, metod plaćanja je po uzeću (online metodi plaćanja zahtjevaju drugu cijenu), neograničen broj kategorija i artikala (ograničeno je samo veličinom vašeg hosting prostora),integracija sa socijalnim mrežama, prilagođen izgled za pregled sa mobilnih uređaja, prijava na Google, analitika posjeta unutar WordPress administracije i slično.</p>
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Webshop;