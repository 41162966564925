import React from 'react';
import Footer from '../Footer';
import Webport from './coolmag.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function WebPortal(){
  return(
    <div>
      <Helmet>
        <title>Izrada web portala već od 600 KM</title>
        <meta name="description" content="Izrada web portala već od 600 KM. Naš tim za vas može da uradi izradu bilo kojeg tipa web portala" />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Izrada modernih web portala</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Vršimo izradu modernih web portala. Web portale radimo po svim najnovijim standardima posebno prilagođenim čitaocima. </p>
    <p>Svaki web portal radimo na način da prvo sa klijentom dogovorimo sve ono što on smatra da je bitno posebno se fokusiramo na to da klijent ima laku mogućnost monetizacije i postavke reklama na portal te lakoću u uređivanju i dodavanju vijesti kako sa desktop uređaja tako i sa mobilnih aparata (telefon, tablet).</p>
    <img src={Webport} className="slikasred"/>
    <p>Naš tim izrađuje web portal koji je kostimiziran samo za vas. Od vašeg budžeta i zahtjeva zavisi i tehnologija u kojoj ćemo raditi web portal. Radimo izradu web portala kako za one sa malim budžetom tako i za one sa jako velikim budžetom.</p>

    <p>Ako već imate portal a želite da ga unaprijedite ili da poboljšate njegovu efikasnost slobodno nam se javite i mi ćemo u skladu sa vašim budžetom (ma koliko on mali bio) uraditi poboljšanja.</p>

    <p>Za vas radimo i održavanje već postojećih portala.</p>

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.</p>
    <p>Izrada modernog portala na WordPress platformi kreće se već od 500 KM. U ovoj cijeni dobijate izradu i dizajn portala prema vašim zahtjevima, mogućnost dodavanje do 5 ediora portala, analitika posjeta unutar administracije web portala, dizajn koji se prilagođava uređaju sa koga se gleda, također mogućnost ulaska u administraicju putem mobilnog telefona te objavu vijesti, ostavljene pozicije za postavku reklama i slično.</p>
    <p>U mogućnosti smo da vam vršimo i registraciju Adsense računa i profesionalno uvezivanje sa web stranicom.</p>
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default WebPortal;