import React from 'react';
import Footer from '../Footer';
import odrzav from './odrzavanjeweb.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Odrzavanje(){
  return(
    <div>
      <Helmet>
        <title>Održavanje web stranica već od 50 KM</title>
        <meta name="description" content="Održavanje web stranica svih vrsta već od 50 KM. Radimo i redizajn te popravku web stranica" />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Održavanje web stranica</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Posjedujete web stranicu ali nemate osobe koja bi radila tehnički dio oko održavanja i nadogradnje iste. Ako je ovo vaš problem onda nam se možete obratiti.</p>
    <p>Naš tim iskusnih web developera za vas nudi usluge održvanja svih tipova web stranica. Imamo više od 15 godina iskustva u ovim poslovima. Radimo kako sa CMS sitemima tako i sa živim kodom.</p>
    <img src={odrzav} className="slikasred"/>
    <p>Za vas smo u stanju da vršimo konstatan update sitema, programskih jezika, dodavanja sadržaja na web stranicu, izradu grafike za istu, migraciju web stranica sa jednog hosting paketa na drugi i slično.</p>

    <p>Možemo vam uraditi i redizajn web stranice ili dogradnju modula i funkcionalnosti.</p>


    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.</p>
    <p>Održavanje web stranice kreće se već od 50 KM za mjesec dana održavanja u koji je uključeno do 40 sati posla.</p>
    <p>Možemo ugovoriti i jednokratno održavanje u tom slučaju radni sat iznosi 20 KM, ukoliko se radi tokom vikenda ili tokom praznika onda je radni sat 25 KM.</p>
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Odrzavanje;