import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import Nav2 from './Nav';
import Jumbotron from './Jumbotron';
import Services from './Services';
import Footer from './Footer';
import {BrowserRouter,Route} from 'react-router-dom';
import Htmlcss from './Htmlcss';
import Home from './Home';
import About from './About';
import Wordpress from './Services/Wordpress';
import Reo from './Services/Reo';
import Php from './Services/Php';
import Template from './Services/Template';
import Seo from './Services/Seo';
import Social from './Services/Social';
import Grafika from './Services/Grafika';
import Zaposli from './Services/Zaposli';
import Contact from './Contact';
import Wordp from './Usluge/Wordp';
import Webshop from './Usluge/Webshop';
import WebPortal from './Usluge/Webportal';
import Namjenske from './Usluge/Namjenske';
import Odrzavanje from './Usluge/Odrzavanje';
import Grafickidiz from './Usluge/Grafickidizajn';
import Digimar from './Usluge/Digimar';
import Redizajn from './Usluge/Redizajn';
import Outsorucing from './Usluge/Outsourcing';
import Kontakt from './Kontakt';
import ScrollToTop from './Services/SrollToTop';
import {Helmet} from 'react-helmet';



function App() {
  return (
    <div className="App">
       <Helmet>
        <title> Izrada i održavanje web stranica</title>
        <meta name="description" content="Vršimo izradu i održavanje web stranica po svim standardima. Vršimo i izradu web trgovina, web portala i slično" />
      
      </Helmet>
      <BrowserRouter>
      <ScrollToTop/>
      <Nav2/>
      <Route exact path="/" component={Home}/>
      <Route exact path="/html-css" component={Htmlcss} onUpdate={() => window.scrollTo(0, 0)} />
      <Route exact path="/about" component={About}/>
      <Route exact path="/wordpress" component={Wordpress}/>
      <Route exact path="/react" component={Reo}/>
      <Route exact path="/php" component={Php}/>
      <Route exact path="/template" component={Template}/>
      <Route exact path="/seo" component={Seo}/>
      <Route exact path="/social" component={Redizajn} />
      <Route exact path="/grapich" component={Grafika}/>
      <Route exact path="/webdeveloper" component={Zaposli}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/wordpress-stranice" component={Wordp}/>
      <Route exact path="/webshop" component={Webshop}/>
      <Route exact path="/webportali" component={WebPortal}/>
      <Route exact path="/namjenske-webstranice" component={Namjenske} />
      <Route exact path="/odrzavanje-webstranica" component={Odrzavanje}/>
      <Route exact path="/graficki-dizajn" component={Grafickidiz} />
      <Route exact path="/digitalni-marketing" component={Digimar} />
      <Route exact path="/redizajn-webstranica" component={Redizajn}/>
      
      <Route exact path="/outsorucing-usluga" component={Outsorucing} />
      <Route exact path="/kontakt" component={Kontakt}/>
      
    
      </BrowserRouter>
    
     
    </div>
  );
}

export default App;
