import React from 'react';
import './Footer.css';


function Footer(){
  return(<div className="footer">
   <div className="row">
     <div className="col-md-4">
     <p>Email <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>
     <p>Telefon/ WhatsApp: +38761884830</p>
     <p>Adresa: Ul. Armije BIH</p>
     <p>Tuzla</p>
     <p>Bosnia i Herzegovina</p>

     </div>
     <div className="col-md-4 mapa">
     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11375.025629018368!2d18.697367!3d44.54062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa87517098cb58da!2sXXL%20STUDIO!5e0!3m2!1shr!2sba!4v1622460544831!5m2!1shr!2sba" width="100%" height="250" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
     </div>
     <div className="col-md-4">
       <p style={{padding:'10px'}}>Mi smo skupina web developera koja poslije svojih redovnih poslova za vas honorarno rade projekte </p>
       <h3>Baza nam je u Tuzli</h3>
       <p style={{padding:'10px'}}>No za nas ne postoje granice, kontaktirajte nas da razvijemo saradnju na obostrano zadovoljstvo</p>
     </div>
   </div>

  
  </div>)
}

export default Footer;