import React from 'react';
import Footer from '../Footer';
import grasl from './grafickidizjan.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Grafickidiz(){
  return(
    <div>
      <Helmet>
        <title>Grafički dizajn</title>
        <meta name="description" content="Grafički dizajn svih vrsta. Radimo usluge grafičkog dizajna kako za pojedince tako i za firme." />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Profesionalni grafički dizajn</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Za svaki uspješan poslovni poduhvat potreban vam je profesionalan grafički dizajn.</p>
    <p>Naši grafički dizajneri u stanju su izraditi grafički dizajn za svaku vašu potrebu. </p>
    <img src={grasl} className="slikasred"/>
    <p>Vršimo izradu grafičkog dizajna po vašim zahtjevima. Radimo izradu logo tipova, infografike, kataloga, banera, reklama, fb reklama, instagram reklama, google reklama i slično.</p>

   

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.</p>
    <p>Izrada osnovnog logotipa u crno bijeloj boji kreće se već od 30 KM.</p>
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Grafickidiz;