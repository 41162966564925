import React from 'react';
import Footer from '../Footer';
import digslika from './digitalmarketing.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Digimar(){
  return(
    <div>
      <Helmet>
        <title>Promocija brenda na društvenim mrežama - Digitalni marketing</title>
        <meta name="description" content="Promovišemo vaš brend u zemlji i inostranstvu. Radimo izradu malih i velikih marketing kampanja. Vršimo reklamiranje na Facebook, Instagram,Google" />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Digitalni marketing</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Da bi prodali više proizvoda ili usluga morate biti primjećeni. U današnjem vremenu to znači da morate biti prisutni na svim socijalnim mrežama kao i na Google pretraživaču.</p>
    <p>Naš tim stručnjaka za digitalni marketing u stanju je da vaš brend promoviše na svim kanalima digitalnog marketinga. Također u stanju smo voditi čitav proces vaše prodajte te raditi od ugovorenog procenta.</p>
    <img src={digslika} className="slikasred"/>
    <p>Za vas vršimo izradu profesionalnih reklama za sve mreže digitalnog marketinga te vršimo postavljanje tih reklama na te mreže. Mi radimo sve za vas od smišljanja strategije prodaje do provođenja marketinga. Za vas profesionalno vodimo vaše biznis profile na socijalnim mrežama.</p>

    <p>Možemo ugovoriti da za vas vršimo i čitav proces prodaje te da radimo kao prodajni menadžeri na osnovu procenta prodaje vaših proizvoda i usluga.</p>

    <p>Također možemo preuzeti svu komunikaciju sa klijentima u vaše ime.</p>
    <p>Radimo punu prezentaciju vašeg brenda i garantujemo uspjeh u podizanju prodaje proizvoda.</p>

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od više faktora kao što su tip poslovne djelatnosti, ciljano tržište i slično.</p>
    <p>Facebook marketing sa svakodnevnim plaćenim oglasima radimo već od 250 KM. U sklopu tog paketa dobijate izradu reklame, grafičku obradu reklame, podešavanje Facebook algoritama za vašu ciljanu grupu, svakodnevno plaćemo oglašavanje na Facebook platformi.</p>
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Digimar;