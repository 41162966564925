import React from 'react';
import Back from './poza.jpg';
let scope = "{Scope}"

function Jumbotron(){
  return(<div>
     <section className="py-5 text-center " style={{background:"#2c3e50"}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
      <h1 className="fw-light" style={{color:'white'}}>{scope}</h1>
        <h1 className="fw-light" style={{color:'white'}}>Prvi bosanski web developer studio</h1>
        <p  style={{color:'white'}}>Mi smo grupa profesionalnih web developera, web dizajnera, grafičkih dizajnera i marketing stručnjaka koji vam nude najpovoljnije usluge u IT sektoru. Radimo izradu web stranica svih vrsta, razvoj modula, redizajn, održavanje, razvoj aplikacija, grafički dizajn, digitalni marketing....  </p>
        <p>
         
          
        </p>
      </div>
    </div>
  </section>
  </div>)
}

export default Jumbotron;