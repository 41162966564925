import React from 'react';

function Kontakt(){
  return(
    <div>

      <iframe src="https://xxlclass.com/bs/kontakt/" style={{width:'100%',height:'100vh'}}></iframe>
    </div>
  )
}

export default Kontakt;