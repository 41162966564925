import React from 'react';
import poz from './poza.jpg';
import Footer from './Footer';
import {Helmet} from 'react-helmet';

function About(){
  return(<div>
    <Helmet>
        <title>O nama</title>
        <meta name="description" content="Mi smo skupina web developera, web dizajnera, grafičkih dizajnera koji poslije redovnog posla za vas rade honorarno vaše projekte" />
      
      </Helmet>
     <section className="py-5 text-center " style={{background:`url(${poz})`,backgroundSize:'cover'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light" style={{color:'white',fontWeight:'600'}}>O nama</h1>
        <p className="lead text-muted"> </p>
        <p>
         
         
        </p>
      </div>
    </div>
  </section>
  <div className="container" style={{marginTop:'60px'}}>
    <h3>Ko smo mi?</h3>
    <p>Mi smo grupa web developera, web dizajnera, grafičkih dizajnera i marketing stručnjaka koji poslije svojih redovnih poslova kroz ovaj studio za vas rade honorarno projekte iz oblasti u kojima rade godinama. </p>

    <p>Svi mi svakodnevno radimo na poslovima koje nudimo kroz ovaj naš studio i možemo vam ponuditi najbolju moguću cijenu za veoma kvalitetnu uslugu. Pošto svi mi radimo za IT kompanije koje rade na internacionalnim projektima mi vam možemo ponuditi savremen pristup u web dizjanu i najmodernije tehnologije.</p>

    <p>Mi vam možemo uraditi bilo koji web projekat, jer imamo resurse za taj vid posla.</p>

    <p>Ako se bavite IT poslovima i imate nezavršene projekte ili projekte u kojima vam je potrebna ispomoć slobodno nam se javite. Mi vam možemo ponuditi razne vrste pomoći i možemo se priključiti projektu u bilo kojoj fazi. </p>

    <p>Imate potrebu za zaposlenjem kvalitetnog IT kadra i to nije problem javite nam se. Mi vam možemo predložiti prave kandidate za vaš oglas. </p>

    <p>Sa svim klijentima radimo na osnovu ugovora o djelu u kome striktno postavljamo uslove kao i rokove kojih se pridržavamo. Za više informacija slobodno nas kontaktirajte</p>
    <p>Možete nas konktatirati na ovaj broj telefona (WhatsApp) +38761884830 ili putem emaila <a href="mailto:info@xxlclass.com">info@xxlclass.com</a></p>
  </div>
  <Footer></Footer>
  </div>)
}

export default About;