import React from 'react';
import Footer from '../Footer';
import rediz from './redeziajn.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Redizajn(){
  return(
    <div>
      <Helmet>
        <title>Redizajn web stranica </title>
        <meta name="description" content="Redizajn web stranica već od 100 KM. Radimo implementaciju svih novih standarda." />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Redizajn već postojećih web stranica</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Naš tim vrši redizajn vaših već postojećih web stranica. </p>
    <p>Vašu staru web stranicu pretvaramo u modernu web stranicu koja je urađena sa svim modernim principima. </p>
    <img src={rediz} className="slikasred"/>
    <p>Za vas radimo kompletan redizajn vaših web stranica ili web aplikacija. Iste prilagođavamo responsive dizajnu tj da se stranica ili aplikacija prilagođava uređaju sa koga se gleda tako da posjetioci sa mobilnih uređaja imaju isto ako ne i bolje korisničko iskustvo kao i posjetioci sa desktop uređaja. Osim toga vršimo promjenu strukutre web stranice,ako je potrebna kako bi se posjetiocu omogućilo da na jednostavan način dođe do informacija.</p>

    <p>Prilikom redizajna radimo strateško planiranje te izradu nacrta web stranice.</p>

    <p>Ukoliko želite da pređete sa neke CMS platforme na drugu npr sa Joomle na WordPress i to radimo za vas.</p>

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta.</p>
    <p>Osnovni redizajn web stranice sa ubacivanjem moderne tipografije te prilagođavanjem pregleda za mobitele kreće se od 50 KM pa na više.</p>
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Redizajn;