import React from 'react';
import './Services.css';
import {Link} from 'react-router-dom';

function Services(){
  return(
    <div className="container" style={{marginTop:'40px'}}>
        <p>Cilj nam je da vam ponudimo kvalitetnu uslugu po pristupačnoj cijeni. Kvalitetu pravdamo našim dugogodišnjim iskustvom te garancijom koju vam dajemo na sve usluge.</p>

        <p>U našem timu rade web developeri koji su redovno zaposleni na pozicijama web developera u IT kompanijama u Bosni i Hercegovini. Svakom projektu pristupamo krajnje ozbiljno i u stanju smo da odradimo projekat od početka do kraja.</p>

        <p>Također u stanju smo da se uključimo u neki već započeti projekat. Ako imate potrebe za zaposlenjem web developera, grafičkog dizajnera, web dizajnera i tu vam možemo pomoći na način da vam preporučimo nekog od naših saradnika</p>

        <p>Otvoreni smo za sve vrste saradnje, nije nam strano ni rad na ino tržište. Ispod vam se nalaze neke od naših usluga koje nudimo:</p>
    
    <div className="usluge ">

      <Link to="/wordpress-stranice">
      <h3>WordPress stranice</h3>
         <p>Izrada WordPress stranica,tema i plugina</p>
      </Link>
    
      
      <Link to="/webshop">
        <h3>Web prodavnice</h3>
        <p>Izrada modernih web prodavnica</p>
        
        </Link>
      <Link to="/webportali">
        <h3>Web portali</h3>
        <p>Izrada web portala</p>
        </Link>
      <Link to="/namjenske-webstranice">
        <h3>Namjenske web stranice</h3>
          <p>Web stranice prilagođene za određene privredne grane</p>
        </Link>
      <Link to="/odrzavanje-webstranica">
        <h3>Održavanje web stranica</h3>
        <p>Usluge održavanja web stranica</p>
        </Link>
      <Link to="/graficki-dizajn">
        <h3>Grafički dizajn</h3>
        <p>Izrada grafičkog identiteta</p>
        </Link>
      <Link to="/digitalni-marketing">
        <h3>Digitalni marketing</h3>
        <p>Promovišemo vaš brend u zemlji i inostranstvu</p>
        </Link>
      <Link to="/redizajn-webstranica">
        <h3>Redizajn web stranica</h3>
        <p>Vašim web stranicama dajemo novo ruho</p>
        </Link>
      
      <Link to="/outsorucing-usluga">
        <h3>Outsourcing</h3>
        <p>Za vaše klijente radimo sve naše usluge</p>
        </Link>
        </div>
    </div>
  )
}

export default Services;