import React from 'react';
import Footer from '../Footer';
import  Grafiksli from '../wordpre.jpg'
import './stil.css';
import {Helmet} from 'react-helmet';

function Wordp(){
  return(
    <div>
      <Helmet>
        <title>Izrada WordPress stranica već od 200 KM</title>
        <meta name="description" content="Vršimo izradu WordPress stranica, tema i pluginova. Imamo više od 10 godina iskustva u ovim poslovima" />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">WordPress web stranice</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Vršimo profesionalnu izradu web stranica na WordPress platformi. WordPress platforma je pogoda za sve one koji žele da imaju potpunu kontrolu nad svojom web stranicom, te za one koji žele samostalno da uređuju istu</p>
    <p>Naši WordPress developeri mogu da vam urade svaki tip web stranice. Možemo da vam uradimo custom teme te custom plugine. Također u mogućnosti smo da vam uradimo izradu teme na osnovu vaše skice koju nam dostavite u PSD fajlu</p>
    <img src={Grafiksli} className="slikasred"/>
    <p>Naši WordPress developer rade i popravak WordPress stranica, redizajn isti, optimizaciju te održavanje istih. Otovreni smo za saradnju sa svim klijentima kako iz zemlje tako i iz inostranstva</p>

    <p>Kupili ste WordPress temu i želite da ista bude profesionalno postavljena, prevedena i slično, slobodno nam se obratite naš tim vam može uraditi taj posao. Cijene se kreću več od 20 KM pa na više u zavinosti od količine posla. </p>

    <p>Naš tim za vas može uraditi i custom stranice u HTML/CSS/JS/PHP koje možemo postaviti na WordPress i slično. Možemo uraditi bilo koju funkcionalnost</p>

    <p>Radimo i outsourcing naših usluga tako da za pojedince ili firme koje se bave ovom vrstom posla možemo raditi sve poslove vezano za WordPress po cijenama koje su znatno niže. Možemo se uključiti na projekat u bilo kojoj fazi.</p>
    <p>Naš WordPress tim čini 10 WordPress developera koji svakodnevno rade u svojim matičnim firmama na projektima WordPress developmenta. Naši WordPress developeri rade sa svim najnovijim tehnikama i tehnologijama</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena. Izrada moderne web stranice na WordPress platformi košta otprilike oko 200 KM. U cijenu toga uključena je izrada WordPress stranice prema vašim željama (može i na osnovu PSD fajla), osnovne funkcionalnosti web stranice, kontakt forma, moderna tipografija i slično. Ovo je dakle okvirna cijena nekog jednostavnog projekta prezentacijske web stranice.</p>
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Wordp;