import React from 'react';
import './Nav.css';
import {Link} from 'react-router-dom';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Logo from './logo.png';
import {LinkContainer} from 'react-router-bootstrap'


function Nav2(){
  return(<div>

<Navbar bg="light" expand="lg">

  <Navbar.Brand href="/"><img src={Logo} /></Navbar.Brand>
 
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
   
      <Nav.Link href="/">Početna</Nav.Link>
    
     
      <Nav.Link href="/about">O nama</Nav.Link>
     
     
      <NavDropdown title="Usluge" id="basic-nav-dropdown">
      
        <NavDropdown.Item href="/wordpress-stranice">WordPress</NavDropdown.Item>
     
       
        <NavDropdown.Item href="/webshop" >Web prodavnice</NavDropdown.Item>
      
     
        <NavDropdown.Item href="/webportali" >Web portali</NavDropdown.Item>
      
       
        <NavDropdown.Item href="/namjenske-webstranice">Namjenske web stranice</NavDropdown.Item>
       
       
        <NavDropdown.Item href="/odrzavanje-webstranica">Održavanje </NavDropdown.Item>
      
        <NavDropdown.Item href="/graficki-dizajn">Grafički dizajn</NavDropdown.Item>
       
      
        <NavDropdown.Item href="/digitalni-marketing">Digitalni marketing</NavDropdown.Item>
        
      
        <NavDropdown.Item href="/redizajn-webstranica">Redizajn web stranica</NavDropdown.Item>

        <NavDropdown.Item href="/outsorucing-usluga">Outsourcing</NavDropdown.Item>
       
      </NavDropdown>
      
      <Nav.Link href="/contact">Kontakt</Nav.Link>
      
    </Nav>
    
  </Navbar.Collapse>
</Navbar>


  
  </div>)
}

export default Nav2;