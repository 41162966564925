import React from 'react';
import Footer from '../Footer';
import otuslik from './outusorcing.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Outsorucing(){
  return(
    <div>
      <Helmet>
        <title>Outsourcing programeskih usluga</title>
        <meta name="description" content="Outsourcing vanila JavaScript,vanila PHP, React.js, Angular.js,Node.js, Laravel i slično." />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Outsourcing naših usluga</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Imate neki IT projekat ali vam je potrebna podrška da bi isti realizovali u zadanom roku. Ako je ovo vaš problem slobodno nas kontaktirajte.</p>
    <p>Naš tim za vas može raditi bilo koje poslove koje se tiču web dizajna i web developmenta. Možemo raditi kako jednostavne poslove kao što su ubacivanje sadržaja, pravod tema,izradu HTML i CSS templata, podešavanje WordPress ili nekih drugih CMS sistema i slično, tako i komplikovane poslove kao što su izrada custom WordPress tema i plugina, izrada custom web stranica i web aplikacija u vanila JS, vanila PHP, React.js, Angular.js,Node.js i slično. </p>
    <img src={otuslik} className="slikasred"/>
    <p>Na raspolaganju imamo više od 20 web developera, 10 grafičkih dizajnera, 10 UX/UI dizajnera, 15 marketing stručnjaka.</p>

    <p>Osim developerskog dijela posla možemo raditi i outsourcing za poslove digitalnog marketinga</p>

  

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.</p>
    
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Outsorucing;