import React from 'react';
import Footer from '../Footer';
import namjens from './namjenskastranica.jpg';
import './stil.css';
import {Helmet} from 'react-helmet';

function Namjenske(){
  return(
    <div>
      <Helmet>
        <title>Izrada namjenskih web stranica - Rent a car, Agencije za nekretnine, Hoteli, Apartmani</title>
        <meta name="description" content="Izrada posebnih web stranica za Rent a car, Agencije za nekretnine, Hotele, Apartmane,Banke,Osiguravajuća društva." />
      
      </Helmet>
    <section className="py-5 text-center " style={{background:'#2c3e50', color:'white'}}>
    <div className="row py-lg-5">
      <div className="col-lg-6 col-md-8 mx-auto">
        <h1 className="fw-light">Izrada namjenskih web stranica</h1>
        <p className="lead text-muted"></p>
        
      </div>
    </div>
  </section>
  <div className="container">
    <p>Naš tim vršimo izradu namjenskih web stranica za sve privredne djelatnosti.</p>
    <p>U stanju smo razviti web stranice za bilo koji tip djelatnosti. Ove web stranice su posebno izrađene tako da se prilagode svrsi privredne djelatnosti kojom se bavite. Cilj ovih web stranica jeste da se vaši posjetioci na što efikasniji način konvertuju u vaše kupce i slično.</p>
    <img src={namjens} className="slikasred"/>
    <p>Naš tim izrađuje sve vrste namjenskih web stranica.Namjenske web stranice radimo u raznim CMS sistemima kao i u raznim programskim jezicima. Koju ćemo tehnologiju primjeniti u razvoju namjenske web stranice to zavisi od vaših zahtjeva i vašeg budžeta.</p>

    <p>Ako imate već neku namjensku web stranicu a potrebno vam je unapređenje iste slobodno nam se javite.</p>

    <p>Za vas radimo i održavanje već postojećih namjenskih web stranica.</p>

    <p>Kako i kod predhodnih usluga i za ovu uslugu vršimo outsourcing za sve pojedince ili firme koje se bave ovim poslom.</p>
    <p>Na sve usluge dajemo granaciju.</p>

    <h3>Cijena usluga</h3>
    <p>Cijena naših usluga zavisi od kompleksnosti projekta te od količine utrošenog radnog vremena.</p>
    <p>Izrada moderne namjenske web stranice kreće se već od 600 KM. Ova web stranica se radi na WordPress platformi i ista ima osnovne funkcionalnosti za vašu djelatnost.</p>
  
    <p>Ukoliko ste zainteresovani neku od ovih naših usluga slobodno nam se javite na ovaj email te pošaljite svoje zahtjeve <a href="mailto:info@xxlclass.com">info@xxlclass.com</a> </p>
    <p>Možete nas kontaktirati i putem telefona na ovaj broj: 061 884 830.</p>
  </div>
  <Footer></Footer>
  </div>
  )
}

export default Namjenske;